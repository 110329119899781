@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wf5vdo');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wf5vdo#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wf5vdo') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wf5vdo') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wf5vdo##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-x-ray {
  &:before {
    content: $icon-x-ray; 
  }
}
.icon-tooth-7 {
  &:before {
    content: $icon-tooth-7; 
  }
}
.icon-toothache {
  &:before {
    content: $icon-toothache; 
  }
}
.icon-dental-5 {
  &:before {
    content: $icon-dental-5; 
  }
}
.icon-tooth-6 {
  &:before {
    content: $icon-tooth-6; 
  }
}
.icon-tooth-5 {
  &:before {
    content: $icon-tooth-5; 
  }
}
.icon-tooth-cleaning {
  &:before {
    content: $icon-tooth-cleaning; 
  }
}
.icon-white-teeth {
  &:before {
    content: $icon-white-teeth; 
  }
}
.icon-teeth {
  &:before {
    content: $icon-teeth; 
  }
}
.icon-smile {
  &:before {
    content: $icon-smile; 
  }
}
.icon-dental-care-3 {
  &:before {
    content: $icon-dental-care-3; 
  }
}
.icon-tooth-4 {
  &:before {
    content: $icon-tooth-4; 
  }
}
.icon-tooth-3 {
  &:before {
    content: $icon-tooth-3; 
  }
}
.icon-dental-care-2 {
  &:before {
    content: $icon-dental-care-2; 
  }
}
.icon-denture {
  &:before {
    content: $icon-denture; 
  }
}
.icon-dentist-3 {
  &:before {
    content: $icon-dentist-3; 
  }
}
.icon-dentist-2 {
  &:before {
    content: $icon-dentist-2; 
  }
}
.icon-dental-4 {
  &:before {
    content: $icon-dental-4; 
  }
}
.icon-gum {
  &:before {
    content: $icon-gum; 
  }
}
.icon-dental-3 {
  &:before {
    content: $icon-dental-3; 
  }
}
.icon-tooth-2 {
  &:before {
    content: $icon-tooth-2; 
  }
}
.icon-dental-care-1 {
  &:before {
    content: $icon-dental-care-1; 
  }
}
.icon-dentist-1 {
  &:before {
    content: $icon-dentist-1; 
  }
}
.icon-dental-implant {
  &:before {
    content: $icon-dental-implant; 
  }
}
.icon-dental-2 {
  &:before {
    content: $icon-dental-2; 
  }
}
.icon-dental-1 {
  &:before {
    content: $icon-dental-1; 
  }
}
.icon-dental {
  &:before {
    content: $icon-dental; 
  }
}
.icon-dentist {
  &:before {
    content: $icon-dentist; 
  }
}
.icon-dental-care {
  &:before {
    content: $icon-dental-care; 
  }
}
.icon-tooth-1 {
  &:before {
    content: $icon-tooth-1; 
  }
}
.icon-broken-tooth {
  &:before {
    content: $icon-broken-tooth; 
  }
}
.icon-tooth {
  &:before {
    content: $icon-tooth; 
  }
}
.icon-breath {
  &:before {
    content: $icon-breath; 
  }
}
.icon-bacteria {
  &:before {
    content: $icon-bacteria; 
  }
}
.icon-baby {
  &:before {
    content: $icon-baby; 
  }
}
.icon-anesthesia {
  &:before {
    content: $icon-anesthesia; 
  }
}

