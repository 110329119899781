$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-x-ray: "\e900";
$icon-tooth-7: "\e901";
$icon-toothache: "\e902";
$icon-dental-5: "\e903";
$icon-tooth-6: "\e904";
$icon-tooth-5: "\e905";
$icon-tooth-cleaning: "\e906";
$icon-white-teeth: "\e907";
$icon-teeth: "\e908";
$icon-smile: "\e909";
$icon-dental-care-3: "\e90a";
$icon-tooth-4: "\e90b";
$icon-tooth-3: "\e90c";
$icon-dental-care-2: "\e90d";
$icon-denture: "\e90e";
$icon-dentist-3: "\e90f";
$icon-dentist-2: "\e910";
$icon-dental-4: "\e911";
$icon-gum: "\e912";
$icon-dental-3: "\e913";
$icon-tooth-2: "\e914";
$icon-dental-care-1: "\e915";
$icon-dentist-1: "\e916";
$icon-dental-implant: "\e917";
$icon-dental-2: "\e918";
$icon-dental-1: "\e919";
$icon-dental: "\e91a";
$icon-dentist: "\e91b";
$icon-dental-care: "\e91c";
$icon-tooth-1: "\e91d";
$icon-broken-tooth: "\e91e";
$icon-tooth: "\e91f";
$icon-breath: "\e920";
$icon-bacteria: "\e921";
$icon-baby: "\e922";
$icon-anesthesia: "\e923";

