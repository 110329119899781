$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-phone: "\e942";
$icon-envelop: "\e945";
$icon-location: "\e947";
$icon-location2: "\e948";
$icon-map: "\e94b";
$icon-map2: "\e94c";
$icon-facebook: "\ea90";
$icon-facebook2: "\ea91";
$icon-instagram: "\ea92";
$icon-whatsapp: "\ea93";

