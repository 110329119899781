/*
Flaticon icon font: Flaticon
Creation date: 28/02/2019 07:01
*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
  url("./Flaticon.woff2") format("woff2"),
  url("./Flaticon.woff") format("woff"),
  url("./Flaticon.ttf") format("truetype"),
  url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

[class^="flaticon-"], [class*="flaticon-"] {
  &:before,
  &:after {
    font-family: Flaticon;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.flaticon-dental-care:before {
  content: "\f100";
}

.flaticon-dental-1:before {
  content: "\f101";
}

.flaticon-dentist:before {
  content: "\f102";
}

.flaticon-baby:before {
  content: "\f103";
}

.flaticon-tooth:before {
  content: "\f104";
}

.flaticon-dental:before {
  content: "\f105";
}

$font-Flaticon-dental-care: "\f100";
$font-Flaticon-dental-1: "\f101";
$font-Flaticon-dentist: "\f102";
$font-Flaticon-baby: "\f103";
$font-Flaticon-tooth: "\f104";
$font-Flaticon-dental: "\f105";